import React from "react";
import ReactApexChart from "react-apexcharts";
import { FaChartBar, FaRegMap, FaUserCircle } from "react-icons/fa";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import WithoutSearchBar from "../../../header/WithoutSearchBar";
import "./style.css";
const FarmDetails = () => {
  const navigate = useNavigate();
  return (
    <div>
      <WithoutSearchBar/>
      <div className="row">
        <div className="col-md-8">
          <div className="row border-1 m-1 p-1 opacity-34">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <p
                      className="card-title"
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        opacity: "0.5",
                      }}
                    >
                      Name
                    </p>
                    <p
                      className="card-text"
                      style={{
                        fontSize: "18px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        textAlign: "left",
                      }}
                    >
                      Yous Putrea
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p
                      className="card-title"
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        opacity: "0.5",
                      }}
                    >
                      Farm Size
                    </p>
                    <p
                      className="card-text"
                      style={{
                        fontSize: "18px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        textAlign: "left",
                      }}
                    >
                      0.96 ha
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p
                      className="card-title"
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        opacity: "0.5",
                      }}
                    >
                      Crop Type
                    </p>
                    <p
                      className="card-text"
                      style={{
                        fontSize: "18px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        textAlign: "left",
                      }}
                    >
                      Paddy Rice
                    </p>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-4">
                    <p
                      className="card-title"
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        opacity: "0.5",
                      }}
                    >
                      Crop Type
                    </p>
                    <p
                      className="card-text"
                      style={{
                        fontSize: "18px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        textAlign: "left",
                      }}
                    >
                      Paddy Rice
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p
                      className="card-title"
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        opacity: "0.5",
                      }}
                    >
                      Loan Amount
                    </p>
                    <p
                      className="card-text"
                      style={{
                        fontSize: "18px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        textAlign: "left",
                      }}
                    >
                      $ 400
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p
                      className="card-title"
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        opacity: "0.5",
                      }}
                    >
                      Overdue Days
                    </p>
                    <p
                      className="card-text"
                      style={{
                        fontSize: "18px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        textAlign: "left",
                      }}
                    >
                      56
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-1 p-2">
            <div className="col-md-6">
              <div className="card">
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    padding: "10px",
                    textAlign: "left",
                  }}
                >
                  Identity Score
                </p>
                <div>
                  <ReactApexChart
                    options={{
                      chart: {
                        type: "radialBar",
                      },
                      plotOptions: {
                        radialBar: {
                          offsetY: 0,
                          startAngle: -90,
                          endAngle: 90,
                          hollow: {
                            margin: 15,
                            size: "60%",
                          },
                          dataLabels: {
                            show: true,
                          },
                        },
                      },
                      colors: ["#70A1E5"],
                      stroke: {
                        lineCap: "round",
                      },
                      labels: ["80.18"],
                    }}
                    
                    series={[70]}
                    type="radialBar"
                    height={250}
                  />
                </div>
                <div>
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "bar",
                      },
                      plotOptions: {
                        bar: {
                          horizontal: true, // Ensure bars are horizontal
                          borderRadius: 4,
                          borderRadiusApplication: "end",
                        },
                      },
                      colors: ["#70A1E5"],
                      title: {
                        show: false,
                      },
                    }}
                    series={[
                      {
                        name: "KYC Completion",
                        color: "#03AFFF",
                        data: [10],
                      },
                      {
                        name: "KYC False Check",
                        color: "#0080FF82",
                        data: [20],
                      },
                    ]}
                    type="bar"
                    height={140}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    padding: "10px",
                    textAlign: "left",
                  }}
                >
                  Socio-demographic Score
                </p>
                <div>
                  <ReactApexChart
                    options={{
                      chart: {
                        type: "radialBar",
                      },
                      plotOptions: {
                        radialBar: {
                          offsetY: 0,
                          startAngle: -90,
                          endAngle: 90,
                          hollow: {
                            margin: 15,
                            size: "60%",
                          },
                          dataLabels: {
                            show: true,
                          },
                        },
                      },
                      colors: ["#9747FF"],
                      stroke: {
                        lineCap: "round",
                      },
                      labels: ["49.05"],
                    }}
                    series={[70]}
                    type="radialBar"
                    height={250}
                  />
                </div>
                <div>
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "bar",
                      },
                      plotOptions: {
                        bar: {
                          horizontal: true, // Ensure bars are horizontal
                          borderRadius: 4,
                          borderRadiusApplication: "end",
                        },
                      },
                      colors: ["#9747FF"],

                      title: {
                        align: "center",
                      },
                    }}
                    series={[
                      {
                        name: "Education Status",
                        color: "#7206FF",
                        data: [42], // Data corresponding to each category
                      },
                      {
                        name: "Family/Society",
                        color: "#9747FF",
                        data: [57], // Data corresponding to each category
                      },
                      {
                        name: "Behaviour Pattern",
                        color: "#D398E7",
                        data: [48], // Data corresponding to each category
                      },
                    ]}
                    type="bar"
                    height={140}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row border-1 p-2">
            <div className="col-md-6">
              <div className="card">
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    padding: "10px",
                    textAlign: "left",
                  }}
                >
                  Financial Score
                </p>
                <div>
                  <ReactApexChart
                    options={{
                      chart: {
                        type: "radialBar",
                      },
                      plotOptions: {
                        radialBar: {
                          offsetY: 0,
                          startAngle: -90,
                          endAngle: 90,
                          hollow: {
                            margin: 15,
                            size: "60%",
                          },
                          dataLabels: {
                            show: true,
                          },
                        },
                      },
                      colors: ["#F4C95D"],
                      stroke: {
                        lineCap: "round",
                      },
                      labels: ["65.91"],
                    }}
                    series={[70]}
                    type="radialBar"
                    height={250}
                  />
                </div>
                <div>
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "bar",
                      },
                      plotOptions: {
                        bar: {
                          horizontal: true, // Ensure bars are horizontal
                          borderRadius: 4,
                          borderRadiusApplication: "end",
                        },
                      },
                      colors: ["#64CC74"],

                      title: {
                        align: "center",
                      },
                    }}
                    series={[
                      {
                        name: "Loan Bank History",
                        color: "#C99309",
                        data: [18],
                      },
                      {
                        name: "Transaction History",
                        color: "#E5AE21",
                        data: [60],
                      },
                      {
                        name: "Default Record",
                        color: "#F4C95D",
                        data: [79],
                      },
                    ]}
                    type="bar"
                    height={190}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    padding: "10px",
                    textAlign: "left",
                  }}
                >
                  Agricultural Score
                </p>
                <div>
                  <ReactApexChart
                    options={{
                      chart: {
                        type: "radialBar",
                      },
                      plotOptions: {
                        radialBar: {
                          offsetY: 0,
                          startAngle: -90,
                          endAngle: 90,
                          hollow: {
                            margin: 15,
                            size: "60%",
                          },
                          dataLabels: {
                            show: true,
                          },
                        },
                      },
                      colors: ["#64CC74"],
                      stroke: {
                        lineCap: "round",
                      },
                      labels: ["55.20"],
                    }}
                    series={[70]}
                    type="radialBar"
                    height={250}
                  />
                </div>
                <div>
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "bar",
                      },
                      plotOptions: {
                        bar: {
                          horizontal: true, // Ensure bars are horizontal
                          borderRadius: 4,
                          borderRadiusApplication: "end",
                        },
                      },
                      colors: ["#64CC74"],

                      title: {
                        align: "center",
                      },
                    }}
                    series={[
                      {
                        name: "Loan Bank History",
                        color: "#0B671A",
                        data: [58], // Data corresponding to each category
                      },
                      {
                        name: "Transaction History",
                        color: "#1A932E",
                        data: [54], // Data corresponding to each category
                      },
                      {
                        name: "Default Record",
                        color: "#64CC74",
                        data: [53], // Data corresponding to each category
                      },
                    ]}
                    type="bar"
                    height={190}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row border-1 p-2">
            <img src={"/assets/icons/dashIMG.png"}  alt="img"/>
          </div>
        </div>
        <div className="col-md-4 mt-2 ">
          <div className="row mr-2">
            <div className="card">
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  padding: "10px",
                  textAlign: "left",
                }}
              >
                Average AgriKreditScore
              </p>
              <div>
                <ReactApexChart
                  options={{
                    chart: {
                      type: "radialBar",
                    },
                    plotOptions: {
                      radialBar: {
                        offsetY: 0,
                        startAngle: -90,
                        endAngle: 90,
                        hollow: {
                          size: "70%",
                        },
                        dataLabels: {
                          show: false,
                        },
                      },
                    },
                    colors: ["#64CC74"],
                    stroke: {
                      lineCap: "round",
                    },
                    labels: ["Completion"],
                  }}
                  series={[70]}
                  type="radialBar"
                  height={300}
                />
                <div
                  className="row justify-content-center mb-3"
                  style={{ marginTop: "-110px" }}
                >
                  <div className="col-6 col-md-3 text-center">
                    <div
                      className="row justify-content-center"
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      62.18%
                    </div>
                    <div className="row justify-content-center opacity-50">
                      Score
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mt-2 mb-3">
                <div className="col-6 col-md-3 text-center">
                  <div
                    className="row justify-content-center"
                    style={{
                      color: "#E65F2B",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    20
                  </div>
                  <div className="row justify-content-center">Bad Score</div>
                </div>
                <div className="col-6 col-md-3 text-center">
                  <div
                    className="row justify-content-center"
                    style={{
                      color: "#DFA510",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    35
                  </div>
                  <div className="row justify-content-center">Mid Score</div>
                </div>
                <div className="col-6 col-md-3 text-center">
                  <div
                    className="row justify-content-center"
                    style={{
                      color: "#1A932E",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    60
                  </div>
                  <div className="row justify-content-center">Good Score</div>
                </div>
              </div>
            </div>
            <div className="card mt-3">
              <div className="row">
                <div className="col-4 p-2 d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="row" style={{ padding: "10px" }}>
                    <FaUserCircle size={40} color="#70A1E5" />
                  </div>
                  <div
                    className="row"
                    style={{ padding: "10px", fontSize: "12px" }}
                  >
                    Outstanding loan/ Borrowing Capability
                  </div>
                  <div className="row">
                    <strong style={{ fontSize: "22px" }}>73K</strong>{" "}
                    {/* Type in bold */}
                  </div>
                </div>

                <div className="col-8 p-2">
                  <ReactApexChart
                    options={{
                      chart: {
                        type: "radialBar",
                      },
                      plotOptions: {
                        radialBar: {
                          offsetY: 0,
                          startAngle: -90,
                          endAngle: 90,
                          hollow: {
                            margin: 15,
                            size: "60%",
                          },
                          dataLabels: {
                            show: true,
                          },
                        },
                      },
                      colors: ["#E5AE21"],
                      stroke: {
                        lineCap: "round",
                      },
                      labels: ["400"],
                    }}
                    series={[70]}
                    type="radialBar"
                    height={350}
                  />
                </div>
              </div>
            </div>
            <div className="card mt-3">
              <div className="row">
                <div className="col-4 p-2 d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="row" style={{ padding: "10px" }}>
                    <FaChartBar size={40} color="#70A1E5" />
                  </div>
                  <div
                    className="row"
                    style={{ padding: "10px", fontSize: "12px" }}
                  >
                    Default Amount
                  </div>
                  <div className="row">
                    <strong style={{ fontSize: "22px" }}>400 K</strong>
                    {/* Type in bold */}
                  </div>
                </div>
                <div className="col-8 p-2">
                  <ReactApexChart
                    options={{
                      chart: {
                        type: "radialBar",
                      },
                      plotOptions: {
                        radialBar: {
                          offsetY: 0,
                          startAngle: -90,
                          endAngle: 90,
                          hollow: {
                            margin: 15,
                            size: "60%",
                          },
                          dataLabels: {
                            show: true,
                          },
                        },
                      },
                      colors: ["#EE201C"],
                      stroke: {
                        lineCap: "round",
                      },
                      labels: ["400"],
                    }}
                    series={[70]}
                    type="radialBar"
                    height={350}
                  />
                </div>
              </div>
            </div>
            <div className="card mt-3">
              <div className="row align-items-center">
                <div className="col-4 d-flex justify-content-center">
                  <div className="row" style={{ padding: "10px" }}>
                    <FaRegMap size={40} color="#70A1E5" />
                  </div>
                </div>
                <div className="col-6">Field Forecast</div>
                <div className="col-2 d-flex justify-content-center">
                  <div className="row" style={{ padding: "10px" }}>
                    <FaArrowUpRightFromSquare
                      size={40}
                      color="#70A1E5"
                      onClick={() => navigate("/FiledAnalysis")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FarmDetails;
