import React, { useState, useEffect } from 'react';
import FarmerInfo from './componenets/FarmerInfo';
import MapInfoGraph from './componenets/MapInfoGraph';
import WithoutSearchBar from '../../../../header/WithoutSearchBar';

const FiledAnalysis = () => {
    const [viewType, setViewType] = useState('NDRE');
    const [filedData, setFiledData] = useState({
        BasicData: {
            farmSize: 200,
            cropType: 'Wheat',
            startDate: '01 Jan 2024',
            endDate: '30 Dec 2024',
            satelliteStatus: 'Active',
        },
        mapData: {
            NDVI: "/assets/images/NDVI.jpg",
            NDRE: "/assets/images/NDRE.jpg",
            Vegetation: "/assets/images/VEG.jpg"
        },
        graphData: {
            NDVI: {
                type: 'NDVI',
                data: [
                    { id: 1, month: 'Jan', value: 0.72 },
                    { id: 2, month: 'Feb', value: 0.75 },
                    { id: 3, month: 'Mar', value: 0.80 },
                    { id: 4, month: 'Apr', value: 0.78 },
                    { id: 5, month: 'May', value: 0.65 },
                    { id: 6, month: 'Jun', value: 0.70 },
                    { id: 7, month: 'Jul', value: 0.73 },
                    { id: 8, month: 'Aug', value: 0.74 },
                    { id: 9, month: 'Sep', value: 0.76 },
                    { id: 10, month: 'Oct', value: 0.77 },
                    { id: 11, month: 'Nov', value: 0.80 },
                    { id: 12, month: 'Dec', value: 0.82 }
                ],
            },
            NDRE: {
                type: 'NDRE',
                data: [
                    { id: 1, month: 'Jan', value: 0.60 },
                    { id: 2, month: 'Feb', value: 0.63 },
                    { id: 3, month: 'Mar', value: 0.68 },
                    { id: 4, month: 'Apr', value: 0.66 },
                    { id: 5, month: 'May', value: 0.62 },
                    { id: 6, month: 'Jun', value: 0.64 },
                    { id: 7, month: 'Jul', value: 0.65 },
                    { id: 8, month: 'Aug', value: 0.67 },
                    { id: 9, month: 'Sep', value: 0.70 },
                    { id: 10, month: 'Oct', value: 0.72 },
                    { id: 11, month: 'Nov', value: 0.74 },
                    { id: 12, month: 'Dec', value: 0.75 }
                ],
            },
            Vegetation: {
                type: 'Vegetation',
                data: [
                    { id: 1, month: 'Jan', value: 0.60 },
                    { id: 2, month: 'Feb', value: 0.63 },
                    { id: 3, month: 'Mar', value: 0.68 },
                    { id: 4, month: 'Apr', value: 0.66 },
                    { id: 5, month: 'May', value: 0.62 },
                    { id: 6, month: 'Jun', value: 0.64 },
                    { id: 7, month: 'Jul', value: 0.67 },
                    { id: 8, month: 'Aug', value: 0.69 },
                    { id: 9, month: 'Sep', value: 0.71 },
                    { id: 10, month: 'Oct', value: 0.73 },
                    { id: 11, month: 'Nov', value: 0.74 },
                    { id: 12, month: 'Dec', value: 0.75 }
                ],
            },
        },

    });

    const handleViewTypeChange = (e) => {
        setViewType(e.target.value);
    };

    useEffect(() => {
        // Any logic to fetch or update data if necessary can go here.
    }, [viewType]); // Only run the effect when viewType changes

    return (
        <div className='container-fluid'>
            <WithoutSearchBar/>
            <div className='row rounded mb-1 p-2'>
                <div className='col-md-8'>
                    <div className='card rounded'>
                        <div className='card-header'>
                        <div className='row'>
                                <div className='col-md-12 d-flex justify-content-between align-items-center'>
                                    <label className='form-label mb-0 w-75'>{viewType} Map Analytics</label>
                                    <select className='form-select' value={viewType} onChange={handleViewTypeChange}>
                                        <option value="Vegetation">Vegetation Meta Index</option>
                                        <option value="NDRE">NDRE</option>
                                        <option value="NDVI">NDVI</option>
                                    </select>
                                </div>
                        </div>
                        </div>
                        <div className='card-body'>
                            
                                <img
                                    src={filedData.mapData[viewType]}
                                    alt={`${viewType} Map`}
                                    className="img-fluid rounded"
                                />
                        
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <FarmerInfo basicInfo={filedData.BasicData} />
                </div>
            </div>

            <div className='row'>
                <MapInfoGraph selectedGraph={filedData.graphData[viewType]} />
            </div>
        </div>
    );
};

export default FiledAnalysis;
