

const LandDetailsIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.396 17.875C9.396 17.4953 9.7038 17.1875 10.0835 17.1875H19.2502C19.6299 17.1875 19.9377 17.4953 19.9377 17.875C19.9377 18.2547 19.6299 18.5625 19.2502 18.5625H10.0835C9.7038 18.5625 9.396 18.2547 9.396 17.875Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.396 11.4583C9.396 11.0786 9.7038 10.7708 10.0835 10.7708H19.2502C19.6299 10.7708 19.9377 11.0786 19.9377 11.4583C19.9377 11.8379 19.6299 12.1458 19.2502 12.1458H10.0835C9.7038 12.1458 9.396 11.8379 9.396 11.4583Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.396 5.0415C9.396 4.66181 9.7038 4.354 10.0835 4.354H19.2502C19.6299 4.354 19.9377 4.66181 19.9377 5.0415C19.9377 5.4212 19.6299 5.729 19.2502 5.729H10.0835C9.7038 5.729 9.396 5.4212 9.396 5.0415Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.9028 2.72212C7.17129 2.9906 7.17129 3.4259 6.9028 3.69439L4.1528 6.44439C3.88432 6.71287 3.44902 6.71287 3.18053 6.44439L2.26386 5.52772C1.99538 5.25924 1.99538 4.82393 2.26386 4.55545C2.53235 4.28696 2.96765 4.28696 3.23614 4.55545L3.66667 4.98598L5.93053 2.72212C6.19902 2.45363 6.63432 2.45363 6.9028 2.72212Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.9028 9.13886C7.17129 9.40735 7.17129 9.84265 6.9028 10.1111L4.1528 12.8611C3.88432 13.1296 3.44902 13.1296 3.18053 12.8611L2.26386 11.9445C1.99538 11.676 1.99538 11.2407 2.26386 10.9722C2.53235 10.7037 2.96765 10.7037 3.23614 10.9722L3.66667 11.4027L5.93053 9.13886C6.19902 8.87038 6.63432 8.87038 6.9028 9.13886Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.9028 15.5554C7.17129 15.8239 7.17129 16.2592 6.9028 16.5276L4.1528 19.2776C3.88432 19.5461 3.44902 19.5461 3.18053 19.2776L2.26386 18.361C1.99538 18.0925 1.99538 17.6572 2.26386 17.3887C2.53235 17.1202 2.96765 17.1202 3.23614 17.3887L3.66667 17.8192L5.93053 15.5554C6.19902 15.2869 6.63432 15.2869 6.9028 15.5554Z" />
        </svg>
    )
}

export default LandDetailsIcon;
