import React from 'react';
import { Link } from 'react-router-dom';
import WithoutSearchBar from '../../../header/WithoutSearchBar';

const LoanApplicationForm = () => {
  return (
    <div className="container">
      <div className="header-comonent border-bottom">
        <WithoutSearchBar title="Loan Application Form" />
      </div>

      <div className="border-bottom p-3 my-4">
        <div className="row mb-2 mt-3">
          <div className="col-md-3">
            <label className="mb-2">Customer ID</label>
            <input type="text" className="form-control" placeholder="CU000877 - LOY THY" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Spouse ID</label>
            <input type="text" className="form-control" placeholder="CU000881 - SUNG MALY" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Application Date</label>
            <input type="date" className="form-control" />
          </div>
        </div>
      </div>

      <h3 className="mb-3">Farmers Details</h3>
      <div className="p-3 mb-4">
        <div className="row mb-3 mt-2">
          <div className="col-md-3">
            <label className="mb-2">First Name</label>
            <input type="text" className="form-control" placeholder="CU000877 - LOY THY" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Last Name</label>
            <input type="text" className="form-control" placeholder="CU000877 - LOY THY" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Gender</label>
            <input type="text" className="form-control" placeholder="Male" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Date of Birth</label>
            <input type="date" className="form-control" />
          </div>
        </div>

        <div className="row mb-3 mt-4">
          <div className="col-md-3">
            <label className="mb-2">Email</label>
            <input type="email" className="form-control" placeholder="loythoy123@gmail.com" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Mobile</label>
            <input type="text" className="form-control" placeholder="97232123290" />
          </div>
        </div>

        <div className="row mb-3 mt-4">
          <div className="col-md-3">
            <label className="mb-2">Spouse Name</label>
            <input type="text" className="form-control" placeholder="SUNG MALY" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Email</label>
            <input type="email" className="form-control" placeholder="sungmaly123@gmail.com" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Gender</label>
            <input type="text" className="form-control" placeholder="Female" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Mobile No</label>
            <input type="text" className="form-control" placeholder="8003483433" />
          </div>
        </div>

        <div className="row mb-3 mt-4">
          <div className="col-md-3">
            <label className="mb-2">Country</label>
            <input type="text" className="form-control" placeholder="Cambodia" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">District</label>
            <input type="text" className="form-control" placeholder="Phenom Pheh" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Village</label>
            <input type="text" className="form-control" placeholder="Phenom Pheh(V)" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Pincode</label>
            <input type="text" className="form-control" placeholder="123456" />
          </div>
        </div>

        <div className="row mb-3 mt-4">
          <div className="col-md-7">
            <label className="mb-2">Address</label>
            <textarea className="form-control" rows="2" placeholder="ABC Plot 183, XYZ"></textarea>
          </div>
        </div>
      </div>

      <Link to="loanDetailsAndPurpose">
        <button className="btn btn-dark">Next</button>
      </Link>
    </div>
  );
};

export default LoanApplicationForm;
