
const KycIcon = () => {
    return(
        <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.17596 6.24913C2.98758 5.28419 4.3791 4.8125 6.41688 4.8125H15.5835C17.6213 4.8125 19.0128 5.28419 19.8245 6.24913C20.6282 7.20469 20.7047 8.46006 20.5758 9.63061L19.888 16.9674C19.7871 17.9098 19.5505 18.943 18.709 19.7117C17.8738 20.4748 16.5798 20.8542 14.6669 20.8542H7.33355C5.4206 20.8542 4.12661 20.4748 3.2914 19.7117C2.44996 18.943 2.21343 17.9098 2.11246 16.9674L2.11149 16.9583L1.42457 9.63063C1.29576 8.46008 1.3722 7.2047 2.17596 6.24913ZM3.22822 7.13421C2.7958 7.6483 2.67409 8.42076 2.79187 9.48522L2.79314 9.49665L3.48011 16.8254C3.57182 17.6773 3.76168 18.279 4.21882 18.6966C4.68298 19.1206 5.56149 19.4792 7.33355 19.4792H14.6669C16.4389 19.4792 17.3174 19.1206 17.7816 18.6966C18.2387 18.279 18.4286 17.6773 18.5203 16.8254L19.2085 9.48521C19.3262 8.42075 19.2046 7.6483 18.7722 7.13421C18.3463 6.62789 17.4599 6.1875 15.5835 6.1875H6.41688C4.5405 6.1875 3.65409 6.62789 3.22822 7.13421Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.06105 3.71276C8.0221 4.01104 8.021 4.35 8.021 4.76658V5.49992C8.021 5.87961 7.71319 6.18742 7.3335 6.18742C6.9538 6.18742 6.646 5.87961 6.646 5.49992L6.646 4.74276C6.64598 4.35453 6.64595 3.93048 6.69762 3.53476C6.75115 3.12473 6.86586 2.68942 7.1346 2.30059C7.69994 1.48262 8.73555 1.14575 10.2668 1.14575H11.7335C13.2648 1.14575 14.3004 1.48262 14.8657 2.30059C15.1345 2.68942 15.2492 3.12473 15.3027 3.53476C15.3544 3.93048 15.3544 4.35453 15.3543 4.74277L15.3543 5.49992C15.3543 5.87961 15.0465 6.18742 14.6668 6.18742C14.2871 6.18742 13.9793 5.87961 13.9793 5.49992V4.76658C13.9793 4.35 13.9782 4.01104 13.9393 3.71276C13.9011 3.42068 13.8325 3.22406 13.7346 3.08237C13.5666 2.8393 13.1355 2.52075 11.7335 2.52075H10.2668C8.86478 2.52075 8.43372 2.8393 8.26573 3.08237C8.1678 3.22406 8.09918 3.42068 8.06105 3.71276Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.85523 11.6882C9.85452 11.7537 9.8545 11.8283 9.8545 11.9167V12.8608C9.8545 13.1189 9.85675 13.3087 9.87916 13.4698C9.90071 13.6246 9.93494 13.7 9.96394 13.7409C9.99629 13.7865 10.1575 13.9792 11.0003 13.9792C11.847 13.9792 12.0063 13.7847 12.0379 13.7395C12.0671 13.6978 12.1012 13.6213 12.1224 13.4646C12.1445 13.3017 12.1462 13.111 12.1462 12.8517V11.9167C12.1462 11.8283 12.1462 11.7537 12.1454 11.6882C12.08 11.6875 12.0054 11.6875 11.917 11.6875H10.0837C9.99526 11.6875 9.92067 11.6875 9.85523 11.6882ZM10.0536 10.3125C10.0636 10.3125 10.0736 10.3125 10.0837 10.3125H11.917C11.927 10.3125 11.9371 10.3125 11.9471 10.3125C12.1498 10.3125 12.3552 10.3124 12.5229 10.331C12.6937 10.35 12.9775 10.4008 13.2052 10.6285C13.4329 10.8561 13.4837 11.1399 13.5026 11.3108C13.5213 11.4784 13.5212 11.6839 13.5212 11.8866C13.5212 11.8966 13.5212 11.9066 13.5212 11.9167V12.862C13.5212 13.1 13.5212 13.3817 13.485 13.649C13.4475 13.9263 13.3653 14.241 13.1642 14.5283C12.7352 15.1407 11.9779 15.3542 11.0003 15.3542C10.0281 15.3542 9.27271 15.1435 8.84214 14.536C8.63969 14.2504 8.55589 13.9367 8.51729 13.6593C8.47954 13.3881 8.4795 13.1024 8.4795 12.8608V11.9167C8.4795 11.9066 8.4795 11.8966 8.4795 11.8866C8.47945 11.6839 8.4794 11.4784 8.49803 11.3108C8.51701 11.1399 8.56778 10.8561 8.79545 10.6285C9.02312 10.4008 9.30694 10.35 9.4778 10.331C9.64542 10.3124 9.85085 10.3125 10.0536 10.3125Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.4021 9.67893C20.6254 9.98601 20.5575 10.416 20.2504 10.6393C18.0356 12.2501 15.5052 13.2081 12.9195 13.5337C12.5427 13.5812 12.1989 13.3143 12.1515 12.9375C12.104 12.5608 12.371 12.217 12.7477 12.1695C15.1119 11.8718 17.4215 10.9965 19.4417 9.52729C19.7488 9.30397 20.1787 9.37186 20.4021 9.67893Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.83458 9.94232C2.04904 9.62899 2.4769 9.54884 2.79023 9.76329C4.76005 11.1115 6.98136 11.9241 9.24342 12.1774C9.62076 12.2197 9.8924 12.5598 9.85015 12.9371C9.8079 13.3145 9.46776 13.5861 9.09042 13.5439C6.60415 13.2655 4.16879 12.3731 2.01361 10.898C1.70028 10.6835 1.62013 10.2557 1.83458 9.94232Z" />
        </svg>
    )
}

export default KycIcon;

