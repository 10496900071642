import React from 'react';
import "./MainDashboard.css";
import { IoIosArrowDown } from "react-icons/io";
import Meter from './Meter';
import ReactApexChart from "react-apexcharts";
import { LuUser2 } from "react-icons/lu";
import { GiNetworkBars } from "react-icons/gi";
import WithSearchBar from '../../../header/WithSearchBar';

const data = [
  {
    id: 1,
    farmer_name: "Vang Sokhom",
    AgriCreditScore: "67.80",
    CurrentLoanAmount: "400",
    OverdueDays: 56,
    Default: "Yes",
  },
  {
    id: 2,
    farmer_name: "Ok Punthea",
    AgriCreditScore: "74.25",
    CurrentLoanAmount: "500",
    OverdueDays: 32,
    Default: "No",
  },
  {
    id: 3,
    farmer_name: "Ou Sopheap",
    AgriCreditScore: "82.40",
    CurrentLoanAmount: "350",
    OverdueDays: 15,
    Default: "No",
  },
  {
    id: 4,
    farmer_name: "Jay Charya",
    AgriCreditScore: "58.60",
    CurrentLoanAmount: "600",
    OverdueDays: 74,
    Default: "Yes",
  },
  {
    id: 5,
    farmer_name: "Choeun Rachana",
    AgriCreditScore: "69.30",
    CurrentLoanAmount: "450",
    OverdueDays: 42,
    Default: "No",
  },
  {
    id: 6,
    farmer_name: "Hun Sopat",
    AgriCreditScore: "77.95",
    CurrentLoanAmount: "300",
    OverdueDays: 0,
    Default: "No",
  },
  {
    id: 7,
    farmer_name: "Meas Seyha",
    AgriCreditScore: "61.50",
    CurrentLoanAmount: "700",
    OverdueDays: 90,
    Default: "Yes",
  },
  {
    id: 8,
    farmer_name: "Ung Soboen",
    AgriCreditScore: "89.75",
    CurrentLoanAmount: "250",
    OverdueDays: 10,
    Default: "No",
  }
];

const generateData = (baseval, count, yrange) => {
  let i = 0;
  const series = [];
  while (i < count) {
    const x = Math.floor(Math.random() * (750 - 1 + 1)) + 1;
    const y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    const z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;

    series.push([x, y, z]);
    baseval += 86400000;
    i++;
  }
  return series;
};

const series = [
  {
    name: 'Bubble1',
    data: generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
      min: 10,
      max: 60
    })
  },
  {
    name: 'Bubble2',
    data: generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
      min: 10,
      max: 60
    })
  },
  {
    name: 'Bubble3',
    data: generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
      min: 10,
      max: 60
    })
  },
  {
    name: 'Bubble4',
    data: generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
      min: 10,
      max: 60
    })
  }
];

const options = {
  chart: {
    height: 350,
    type: 'bubble'
  },
  dataLabels: {
    enabled: false
  },
  fill: {
    opacity: 0.8
  },
  xaxis: {
    tickAmount: 12,
    type: 'category'
  },
  yaxis: {
    max: 70
  }
};

const MainDashboard = () => {
  return (
    <div className='container-fluid'>
      <WithSearchBar/>
      <div className='main_dashboard_container'>
        <div className='left_area'>
          <div className='farm_location_container'>
            <div className='farm_location_upper'>
              <div className='farm_location_size'>
                Farm location and loan size
              </div>
              <div className='district_facilitator_dropdown'>
                <div className='district_facilitator_text'>
                  District Facilitator
                </div>
                <div className='district_facilitator_dropdown_icon'>
                  <IoIosArrowDown />
                </div>
              </div>
            </div>
            <div className='farm_location_lower'>
              <ReactApexChart options={options} series={series} type="bubble" height={350} />
            </div>
          </div>

          <div className='recently_added_farmers'>
            <div className='recently_added_farmers_text'>
              Recently Added Farmers
            </div>
            <div className='recently_added_farmers_list'>
              <div className='farmer_item_header'>
                <div className='farmer_sl'>
                  SL
                </div>
                <div className='farmer_name'>
                  Name
                </div>
                <div className='AgriCreditScore'>
                  AgriCredit Score
                </div>
                <div className='CurrentLoanAmount'>
                  Current Loan Amount
                </div>
                <div className='OverdueDays'>
                  Overdue Days
                </div>
                <div className='Default'>
                  Default
                </div>
              </div>

              {data.map((item) => (
                <div className='farmer_item'>
                  <div className='farmer_sl'>
                    {item.id}
                  </div>
                  <div className='farmer_name'>
                    {item.farmer_name}
                  </div>
                  <div className='AgriCreditScore'>
                    {item.AgriCreditScore}
                  </div>
                  <div className='CurrentLoanAmount'>
                    {item.CurrentLoanAmount}
                  </div>
                  <div className='OverdueDays'>
                    {item.OverdueDays}
                  </div>
                  <div className='Default'>
                    {item.Default}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='right_area'>
          <div className="agiCreditScore_container">
            <div>
              <div className='agiCreditScore_upper_div'>
                <div className='agiCreditScore_text'>
                  Average AgriKreditScore
                </div>

                <div className='agiCreditScore_dropdown'>
                  <div className='agiCreditScore_dropdown_text'>
                    All
                  </div>
                  <div className='agiCreditScore_dropdown_icon'>
                    <IoIosArrowDown />
                  </div>
                </div>
              </div>
              <ReactApexChart
                options={{
                  chart: {
                    type: "radialBar",
                  },
                  plotOptions: {
                    radialBar: {
                      offsetY: 0,
                      startAngle: -90,
                      endAngle: 90,
                      hollow: {
                        size: "70%",
                      },
                      dataLabels: {
                        show: false,
                      },
                    },
                  },
                  colors: ["#64CC74"],
                  stroke: {
                    lineCap: "round",
                  },
                  labels: ["Completion"],
                }}
                series={[70]}
                type="radialBar"
                height={300}
              />
              <div
                className="row justify-content-center mb-3"
                style={{ marginTop: "-110px" }}
              >
                <div className="col-6 col-md-3 text-center">
                  <div
                    className="row justify-content-center"
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    62.18%
                  </div>
                  <div className="row justify-content-center opacity-50">
                    Score
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-2 mb-3">
              <div className="col-6 col-md-3 text-center">
                <div
                  className="row justify-content-center"
                  style={{
                    color: "#E65F2B",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  20
                </div>
                <div className="row justify-content-center">Bad Score</div>
              </div>
              <div className="col-6 col-md-3 text-center">
                <div
                  className="row justify-content-center"
                  style={{
                    color: "#DFA510",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  35
                </div>
                <div className="row justify-content-center">Mid Score</div>
              </div>
              <div className="col-6 col-md-3 text-center">
                <div
                  className="row justify-content-center"
                  style={{
                    color: "#1A932E",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  60
                </div>
                <div className="row justify-content-center">Good Score</div>
              </div>
            </div>
          </div>

          <div className='outStanding_loans_container'>
            <div className='row'>
              <div className='col-6'>
                <div className='outStanding_loans_icon'>
                  <LuUser2 />
                </div>
                <div className='outStanding_loans_text4353'>
                  Avg OutStanding loan
                </div>
                <div className='outStanding_loans_text4543'>
                  73 K
                </div>
                <div className='outStanding_loans_text2654'>
                  Outstanding loan/ Borrowing Capability
                </div>

              </div>
              <div className='col-6'>
                <div className='chart_div_outstandingloan'>
                  <div className='chart_div_outstandingloan_inner'>
                    <ReactApexChart
                      options={{
                        chart: {
                          type: "radialBar",
                        },
                        plotOptions: {
                          radialBar: {
                            offsetY: 0,
                            startAngle: -90,
                            endAngle: 90,
                            hollow: {
                              margin: 30,
                              size: "70%",
                            },
                            dataLabels: {
                              show: false,
                            },
                          },
                        },
                        colors: ["#e5ae21"],
                        stroke: {
                          lineCap: "round",
                        },
                        labels: ["Completion"],
                      }}
                      series={[50]}
                      type="radialBar"
                      height={400}
                      width={250}
                    />
                    <div className='chart_div_outstandingloan_text22'>
                      73 K
                    </div>
                    <div className='chart_div_outstandingloan_text62'>
                      <div className='chart_div_outstandingloan_text6_inner'>
                        0K
                      </div>
                      <div className='chart_div_outstandingloan_text6_inner2'>
                        146K
                      </div>
                    </div>

                    <div className='chart_div_outstandingloan_text22'>
                      73 K
                    </div>
                    <div className='chart_div_outstandingloan_text62'>
                      <div className='chart_div_outstandingloan_text6_inner'>
                        0K
                      </div>
                      <div className='chart_div_outstandingloan_text6_inner2'>
                        146K
                      </div>
                    </div>

                    <div className='chart_div_outstandingloan_text2'>
                      73 K
                    </div>
                    <div className='chart_div_outstandingloan_text6'>
                      <div className='chart_div_outstandingloan_text6_inner'>
                        0K
                      </div>
                      <div className='chart_div_outstandingloan_text6_inner2'>
                        146K
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='outStanding_loans_container'>
            <div className='row'>
              <div className='col-6'>
                <div className='outStanding_loans_icon2'>
                  <GiNetworkBars />
                </div>
                <div className='outStanding_loans_text4353'>
                  Avg OutStanding loan
                </div>
                <div className='outStanding_loans_text4543'>
                  24 K
                </div>
                <div className='outStanding_loans_text2654'>
                  Outstanding loan/ Borrowing Capability
                </div>

              </div>
              <div className='col-6'>
                <div className='chart_div_outstandingloan'>
                  <div className='chart_div_outstandingloan_inner'>
                    <ReactApexChart
                      options={{
                        chart: {
                          type: "radialBar",
                        },
                        plotOptions: {
                          radialBar: {
                            offsetY: 0,
                            startAngle: -90,
                            endAngle: 90,
                            hollow: {
                              margin: 30,
                              size: "70%",
                            },
                            dataLabels: {
                              show: false,
                            },
                          },
                        },
                        colors: ["#ff0000"],
                        stroke: {
                          lineCap: "round",
                        },
                        labels: ["Completion"],
                      }}
                      series={[25]}
                      type="radialBar"
                      height={400}
                      width={250}
                    />
                    <div className='chart_div_outstandingloan_text'>
                      24 K
                    </div>
                    <div className='chart_div_outstandingloan_text6'>
                      <div className='chart_div_outstandingloan_text6_inner'>
                        OK
                      </div>
                      <div className='chart_div_outstandingloan_text6_inner2'>
                        146K
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainDashboard
