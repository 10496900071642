import React from 'react';
import { Link } from 'react-router-dom';
import WithSearchBar from '../../../header/WithSearchBar';

const CheckListItems = () => {
    return (
        <div className="container">
            <div className="header-comonent border-bottom">
                <WithSearchBar title="Check List Items" />
            </div>


            <div className="p-3 mb-4">
                <div className="row mb-3 mt-2">
                    <div className="col-md-3">
                        <label className="mb-2">Check List Item</label>
                        <input type="text" className="form-control" placeholder="ID FB and Other Letter Verify" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Yes/No</label>
                        <input type="text" className="form-control" placeholder="Yes" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Yes Date</label>
                        <input type="text" className="form-control" placeholder="2024-07-09" />
                    </div>
                </div>

                <div className="row mb-3 mt-4">
                    <div className="col-md-3">
                        <label className="mb-2">Check List Item</label>
                        <input type="text" className="form-control" placeholder="Wings/GPS/Simcard" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Yes/No</label>
                        <input type="text" className="form-control" placeholder="Yes" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Yes Date</label>
                        <input type="text" className="form-control" placeholder="2024-07-09" />
                    </div>
                </div>

                <div className="row mb-3 mt-4">
                    <div className="col-md-3">
                        <label className="mb-2">Check List Item</label>
                        <input type="text" className="form-control" placeholder="BS File" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Yes/No</label>
                        <input type="text" className="form-control" placeholder="Yes" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Yes Date</label>
                        <input type="text" className="form-control" placeholder="2024-07-09" />
                    </div>
                </div>
            </div>


            <Link to="/loanApplicationForm/loanDetailsAndPurpose">
                <button className="btn btn-light border-dark m-3">Back</button>
            </Link>
            <Link to="guarantor">
                <button className="btn btn-dark">Next</button>
            </Link>
        </div>
    );
};

export default CheckListItems;
