import React from 'react'

const MenuSettings = () => {
  return (
    <div>
      MenuSettings
    </div>
  )
}

export default MenuSettings
