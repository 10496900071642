import React from 'react';
import WithSearchBar from '../../../header/WithSearchBar';

const LivingExpense = () => {
    return (
        <div className="container">
            <div className="header-comonent border-bottom">
                <WithSearchBar title="Living Expense" />
            </div>

            <div className="p-3 mb-4">
                <div className="row mb-3 mt-2">
                    <div className="col-md-3">
                        <label className="mb-2">LivingExpense</label>
                        <input type="text" className="form-control" placeholder="3,600.00" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Monthly Repay</label>
                        <input type="text" className="form-control" placeholder="All" />
                    </div>
                </div>
            </div>

            <button className="btn btn-dark">Register</button>
        </div>
    );
};

export default LivingExpense;
