import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Dashboard from './components/dashboard/Dashboard';
import Login from './components/auth/Login';

function App() {
  // const token = localStorage.getItem('token');
  const token = "token";

  return (
    <div className="App">
      <Router>
          {token ? <Dashboard /> : <Login />}
      </Router>
    </div>
  );
}

export default App;
