import React from 'react';
import { Link } from 'react-router-dom';
import WithSearchBar from '../../../header/WithSearchBar';

const Guarantor = () => {
    return (
        <div className="container">
            <div className="header-comonent border-bottom">
                <WithSearchBar title="Guarantor" />
            </div>

            <div className="p-3 mb-4">
                <h4 className="mb-4">Add Guarantor</h4>

                <div className="row mb-3 mt-2">
                    <div className="col-md-3">
                        <label className="mb-2">Guarantor</label>
                        <input type="text" className="form-control" placeholder="Select Guarantor" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Spouse Guarantor</label>
                        <input type="text" className="form-control" placeholder="Select SpouseGuarantorID" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Relation Indicator</label>
                        <input type="text" className="form-control" placeholder="--None--" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Type</label>
                        <input type="text" className="form-control" placeholder="Guarantor" />
                    </div>
                </div>
            </div>


            <Link to="/loanApplicationForm/loanDetailsAndPurpose/checkListItems">
                <button className="btn btn-light border-dark m-3">Back</button>
            </Link>
            <Link to="bussinessType">
                <button className="btn btn-dark">Next</button>
            </Link>
        </div>
    );
};

export default Guarantor;
