const farmersIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4172 2.52075C15.4861 2.52075 14.7397 3.26711 14.7397 4.19825C14.7397 5.09468 15.4292 5.81715 16.302 5.87376C16.3752 5.86726 16.4501 5.86726 16.5234 5.87375C17.3946 5.8172 18.0871 5.09567 18.0947 4.1958C18.0934 3.27321 17.3458 2.52075 16.4172 2.52075ZM13.3647 4.19825C13.3647 2.50773 14.7267 1.14575 16.4172 1.14575C18.1095 1.14575 19.4697 2.51863 19.4697 4.19825V4.20316H19.4697C19.4579 5.85218 18.1599 7.19106 16.5246 7.2503C16.4872 7.25166 16.4498 7.24996 16.4127 7.24522C16.3756 7.24996 16.3381 7.25166 16.3007 7.2503C14.6663 7.19109 13.3647 5.85195 13.3647 4.19825Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4093 8.11356C16.8029 7.86942 18.392 8.10218 19.5472 8.86873L19.5485 8.86955C20.3381 9.39595 20.824 10.1587 20.824 11.0091C20.824 11.8594 20.3381 12.6222 19.5485 13.1486C18.4018 13.9161 16.8317 14.148 15.4416 13.9146C15.0672 13.8517 14.8146 13.4972 14.8774 13.1228C14.9403 12.7483 15.2948 12.4957 15.6693 12.5586C16.7903 12.7468 17.988 12.5389 18.7845 12.0054L18.7858 12.0045C19.2887 11.6693 19.449 11.2954 19.449 11.0091C19.449 10.7229 19.2888 10.3492 18.7864 10.014C17.98 9.47922 16.7643 9.27212 15.6466 9.46793C15.2726 9.53345 14.9163 9.28337 14.8508 8.90937C14.7852 8.53537 15.0353 8.17907 15.4093 8.11356Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.50244 4.19825C2.50244 2.51863 3.86268 1.14575 5.55494 1.14575C7.24547 1.14575 8.60744 2.50773 8.60744 4.19825C8.60744 5.85195 7.30591 7.19109 5.6715 7.2503C5.63409 7.25166 5.59664 7.24996 5.55953 7.24522C5.52242 7.24996 5.48497 7.25166 5.44755 7.2503C3.81228 7.19106 2.51424 5.85218 2.50246 4.20316L2.50244 4.19825ZM3.87744 4.19581C3.88512 5.09567 4.57757 5.8172 5.44884 5.87375C5.52204 5.86726 5.59702 5.86726 5.67022 5.87376C6.54299 5.81715 7.23244 5.09468 7.23244 4.19825C7.23244 3.26711 6.48608 2.52075 5.55494 2.52075C4.62636 2.52075 3.87876 3.27321 3.87744 4.19581Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.18556 10.014C3.992 9.47922 5.2076 9.27212 6.32536 9.46793C6.69936 9.53345 7.05566 9.28337 7.12118 8.90937C7.18669 8.53537 6.93662 8.17907 6.56262 8.11356C5.169 7.86942 3.57993 8.10218 2.4247 8.86873L2.4247 8.86872L2.42347 8.86955C1.63386 9.39595 1.14795 10.1587 1.14795 11.0091C1.14795 11.8592 1.63361 12.6218 2.42285 13.1482C3.56956 13.916 5.13995 14.148 6.53032 13.9146C6.90477 13.8517 7.15737 13.4972 7.0945 13.1228C7.03164 12.7483 6.67712 12.4957 6.30266 12.5586C5.18161 12.7468 3.98398 12.5389 3.18742 12.0054L3.18618 12.0045C2.68328 11.6693 2.52295 11.2954 2.52295 11.0091C2.52295 10.7229 2.68315 10.3492 3.18556 10.014Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9172 9.36816C9.98611 9.36816 9.23975 10.1145 9.23975 11.0457C9.23975 11.9421 9.9292 12.6646 10.802 12.7212C10.8762 12.7146 10.9523 12.7147 11.0264 12.7214C11.8906 12.6719 12.587 11.9479 12.5947 11.0432C12.5934 10.1206 11.8458 9.36816 10.9172 9.36816ZM7.86475 11.0457C7.86475 9.35514 9.22671 7.99316 10.9172 7.99316C12.6095 7.99316 13.9697 9.36604 13.9697 11.0457V11.0506H13.9697C13.958 12.6952 12.6633 14.0502 11.0197 14.0979C10.9839 14.0989 10.9481 14.0972 10.9127 14.0926C10.8756 14.0974 10.8381 14.0991 10.8007 14.0977C9.16627 14.0385 7.86475 12.6994 7.86475 11.0457Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0031 14.8818C12.0742 14.8818 13.1823 15.1486 14.0485 15.7261C14.8381 16.2525 15.324 17.0152 15.324 17.8656C15.324 18.7158 14.8383 19.4785 14.0488 20.0049C13.179 20.5865 12.0713 20.8562 10.9997 20.8562C9.92809 20.8562 8.82039 20.5865 7.95051 20.0049C7.16109 19.4785 6.67529 18.7158 6.67529 17.8656C6.67529 17.0152 7.16121 16.2525 7.95081 15.7261L7.95246 15.7249L7.95247 15.725C8.82236 15.1486 9.93178 14.8818 11.0031 14.8818ZM8.71271 16.8707C8.21045 17.2058 8.05029 17.5794 8.05029 17.8656C8.05029 18.1519 8.21063 18.5258 8.71352 18.861L8.71435 18.8616C9.31105 19.2606 10.1373 19.4812 10.9997 19.4812C11.862 19.4812 12.6883 19.2606 13.285 18.8616L13.2858 18.861C13.7887 18.5258 13.949 18.1519 13.949 17.8656C13.949 17.5793 13.7887 17.2054 13.2858 16.8701C12.6945 16.4759 11.8684 16.2568 11.0031 16.2568C10.1384 16.2568 9.30942 16.4757 8.71271 16.8707Z" />
        </svg>

    )
}

export default farmersIcon