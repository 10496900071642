import React from 'react';
import { FaArrowsAltH, FaSeedling, FaCalendarAlt, FaSatellite } from 'react-icons/fa';

const FarmerInfo = ({ basicInfo }) => {
    
    console.log(basicInfo);
  return (
    <div className="container" style={{ fontFamily: 'Inter' }}>
     
        {/* Card 1 - Farm Size */}
        <div className="d-flex align-items-center p-1 mb-2 border rounded bg-light">
          <div className="bg-danger text-white p-2 rounded-circle" style={{marginRight: '10px'}}>
            <FaArrowsAltH size={30} />
          </div>
          <div className="ml-3" style={{ fontFamily: 'Inter' }}>
            Farm Size
            <p className="mb-0"><strong>{basicInfo.farmSize} </strong></p>
          </div>
        </div>

        {/* Card 2 - Crop Type */}
        <div className="d-flex align-items-center p-1 mb-2 border rounded bg-light">
          <div className="bg-success text-white p-2 rounded-circle" style={{marginRight: '10px'}}>
            <FaSeedling size={30} />
          </div>
          <div className="ml-3" style={{ fontFamily: 'Inter' }}>
            Crop Type
            <p className="mb-0"> <strong>{basicInfo.cropType}</strong></p>
          </div>
        </div>

        {/* Card 3 - Start Date */}
        <div className="d-flex align-items-center p-1 mb-2 border rounded bg-light">
          <div className="bg-primary text-white p-2 rounded-circle" style={{marginRight: '10px'}}>
            <FaCalendarAlt size={30} />
          </div>
          <div className="ml-3" style={{ fontFamily: 'Inter' }}>
            Start Date
            <p className="mb-0"><strong>{basicInfo.startDate}</strong></p>
          </div>
        </div>

        {/* Card 4 - End Date */}
        <div className="d-flex align-items-center p-1 mb-2 border rounded bg-light">
          <div className="bg-warning text-white p-2 rounded-circle" style={{marginRight: '10px'}}>
            <FaCalendarAlt size={30} />
          </div>
          <div className="ml-3" style={{ fontFamily: 'Inter' }}>
            End Date
            <p className="mb-0"><strong> {basicInfo.endDate}</strong></p>
          </div>
        </div>

        {/* Card 5 - Satellite */}
        <div className="d-flex align-items-center p-1 mb-3 border rounded bg-light">
          <div className="bg-info text-white p-2 rounded-circle" style={{marginRight: '10px'}}>
            <FaSatellite size={30} />
          </div>
          <div className="ml-3" >
            Satellite
            <p className="mb-0"><strong> {basicInfo.satelliteStatus} </strong></p>
          </div>
        </div>

    </div>
  );
};

export default FarmerInfo;
