import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Form, InputGroup, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { FaEye, FaEdit, FaTrash, FaSearch } from 'react-icons/fa';
import WithoutSearchBar from '../../../header/WithoutSearchBar';


const Farmers = () => {
    const [farmerData, setFarmerData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);  // You can adjust the number of items per page here
    const navigate = useNavigate();

    useEffect(() => {
        // Dummy farmer data
        setFarmerData([{ id: 1, NationalId: '100101102', name: 'Vang Sokhom', Commune: 'Commune A1', AgriCreditScore: '85', CurrentLoan: '20000', OverDueDays: '0', status: 'Approved', isDefault: 'Yes' },
            { id: 2, NationalId: '103104105', name: 'Ok Punthea', Commune: 'Commune B1', AgriCreditScore: '90', CurrentLoan: '25000', OverDueDays: '1', status: 'Pending', isDefault: 'No' },
            { id: 3, NationalId: '106107108', name: 'Ou Sopheap', Commune: 'Commune C1', AgriCreditScore: '88', CurrentLoan: '23000', OverDueDays: '0', status: 'Approved', isDefault: 'Yes' },
            { id: 4, NationalId: '109110111', name: 'Jay Charya', Commune: 'Commune D1', AgriCreditScore: '91', CurrentLoan: '21000', OverDueDays: '2', status: 'Pending', isDefault: 'No' },
            { id: 5, NationalId: '112113114', name: 'Choeun Rachana', Commune: 'Commune E1', AgriCreditScore: '82', CurrentLoan: '20000', OverDueDays: '3', status: 'Rejected', isDefault: 'Yes' },
            { id: 6, NationalId: '115116117', name: 'Hun Sopat', Commune: 'Commune F1', AgriCreditScore: '87', CurrentLoan: '24000', OverDueDays: '4', status: 'Approved', isDefault: 'No' },
            { id: 7, NationalId: '118119120', name: 'Meas Seyha', Commune: 'Commune G1', AgriCreditScore: '92', CurrentLoan: '22000', OverDueDays: '0', status: 'Pending', isDefault: 'Yes' },
            { id: 8, NationalId: '121122123', name: 'Ung Soboen', Commune: 'Commune H1', AgriCreditScore: '89', CurrentLoan: '25000', OverDueDays: '5', status: 'Approved', isDefault: 'No' },
            { id: 9, NationalId: '124125126', name: 'Thy Samphy', Commune: 'Commune I1', AgriCreditScore: '85', CurrentLoan: '23000', OverDueDays: '2', status: 'Pending', isDefault: 'Yes' },
            { id: 10, NationalId: '127128129', name: 'Yim Seyha', Commune: 'Commune J1', AgriCreditScore: '91', CurrentLoan: '26000', OverDueDays: '1', status: 'Approved', isDefault: 'No' },
            { id: 11, NationalId: '130131132', name: 'Chen Kalianne', Commune: 'Commune K1', AgriCreditScore: '84', CurrentLoan: '27000', OverDueDays: '0', status: 'Pending', isDefault: 'Yes' },
            { id: 12, NationalId: '133134135', name: 'Phan Heng', Commune: 'Commune L1', AgriCreditScore: '92', CurrentLoan: '24000', OverDueDays: '3', status: 'Approved', isDefault: 'No' },
            { id: 13, NationalId: '136137138', name: 'Hu Sothear', Commune: 'Commune M1', AgriCreditScore: '78', CurrentLoan: '22000', OverDueDays: '6', status: 'Pending', isDefault: 'Yes' },
            { id: 14, NationalId: '139140141', name: 'Iem Sorpheny', Commune: 'Commune N1', AgriCreditScore: '80', CurrentLoan: '20000', OverDueDays: '0', status: 'Approved', isDefault: 'No' },
            { id: 15, NationalId: '142143144', name: 'Ok Sopheaktra', Commune: 'Commune O1', AgriCreditScore: '83', CurrentLoan: '23000', OverDueDays: '4', status: 'Rejected', isDefault: 'Yes' },
            { id: 16, NationalId: '145146147', name: 'Iam Choum', Commune: 'Commune P1', AgriCreditScore: '86', CurrentLoan: '25000', OverDueDays: '2', status: 'Pending', isDefault: 'No' },
            { id: 17, NationalId: '148149150', name: 'Chhorn Sovanna', Commune: 'Commune Q1', AgriCreditScore: '89', CurrentLoan: '22000', OverDueDays: '1', status: 'Approved', isDefault: 'Yes' },
            { id: 18, NationalId: '151152153', name: 'Sin Phirum', Commune: 'Commune R1', AgriCreditScore: '91', CurrentLoan: '24000', OverDueDays: '0', status: 'Approved', isDefault: 'No' },
            { id: 19, NationalId: '154155156', name: 'Ma Rangsei', Commune: 'Commune S1', AgriCreditScore: '90', CurrentLoan: '28000', OverDueDays: '5', status: 'Rejected', isDefault: 'Yes' }]);
    }, []);

    // Filter data based on search term and status
    const filteredData = farmerData
        .filter((farmer) => (statusFilter === 'All' || farmer.status === statusFilter))
        .filter((farmer) => farmer.name.toLowerCase().includes(searchTerm.toLowerCase()));

    // Pagination Logic
    const totalItems = filteredData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchChange = (e) => setSearchTerm(e.target.value);
    const handleStatusFilter = (status) => setStatusFilter(status);

    const handleAction = (action, id) => {
        switch (action) {
            case 'view':
                navigate(`/FarmDetails`);
                break;
            case 'edit':
                navigate(`/FarmDetails`);
                break;
            case 'delete':
                alert(`Delete farmer with ID: ${id}`);
                break;
            default:
                break;
        }
    };

    const columns = [
        { dataField: 'NationalId', text: 'National ID', sort: true },
        { dataField: 'name', text: 'Name', sort: true },
        { dataField: 'Commune', text: 'Commune' },
        { dataField: 'AgriCreditScore', text: 'AgriKredit Score' },
        { dataField: 'CurrentLoan', text: 'Current Loan' },
        { dataField: 'OverDueDays', text: 'Overdue Days' },
        { dataField: 'status', text: 'Status' },
        {
            dataField: 'isDefault',
            text: 'Default',
            headerStyle: { borderBottom: '2px solid #ddd' },
            style: { textAlign: 'center' },
            formatter: (cell) => {
                
                return <div>{cell}</div>;

            }
        },
        {
            dataField: 'actions',
            text: 'Action',
            formatter: (cell, row) => (
                <div>
                    <FaEye className="action-icon  cursor-pointer" onClick={() => handleAction('view', row.id)}  style={{ cursor: 'pointer',color:'#1A932E' }} />
                    <FaEdit className="action-icon  mx-2 cursor-pointer" onClick={() => handleAction('edit', row.id)}   style={{ cursor: 'pointer',color:'#1A932E' }}/>
                    <FaTrash className="action-icon  cursor-pointer" onClick={() => handleAction('delete', row.id)}   style={{ cursor: 'pointer',color:'#1A932E' }}/>
                </div>
            ),
        },
    ];

    return (
        <div className="mb-3 p-3">
            <WithoutSearchBar/>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-4">
                <InputGroup className="mb-2 mb-md-0" style={{ maxWidth: '300px' }}>
                    <InputGroup.Text style={{ borderRadius: '25px 0px 0px 25px', backgroundColor: 'white', border: 'none' }}>
                        <FaSearch />
                    </InputGroup.Text>
                    <Form.Control
                        placeholder="Search Users"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        style={{ borderRadius: '0px 25px 25px 0px', border: 'none' }}
                    />
                </InputGroup>

                <Dropdown onSelect={handleStatusFilter} className="mt-2 mt-md-0 bg-white">
                    <Dropdown.Toggle variant="white" className="border-radius-25 px-3 py-1">
                        Status: {statusFilter}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item eventKey="All">All</Dropdown.Item>
                        <Dropdown.Item eventKey="Pending">Pending</Dropdown.Item>
                        <Dropdown.Item eventKey="Approved">Approved</Dropdown.Item>
                        <Dropdown.Item eventKey="Rejected">Rejected</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <div style={{ overflowX: 'auto', marginBottom: '20px' }}>
                <BootstrapTable
                    keyField="id"
                    data={currentItems}
                    columns={columns}
                    bordered={false}
                    noDataIndication="No farmers available"
                    className="farmers-table"
                />
            </div>

            {/* Pagination Controls */}
            <div className="d-flex justify-content-center align-items-center my-3">
                

                {/* Page Number Buttons */}
                {[...Array(totalPages).keys()].map(pageNumber => (
                    <Button
                        key={pageNumber + 1}
                        variant={currentPage === pageNumber + 1 ? 'primary' : 'outline-primary'}
                        onClick={() => handlePageChange(pageNumber + 1)}
                        className="mx-1"
                    >
                        {pageNumber + 1}
                    </Button>
                ))}

                
            </div>
        </div>
    );
};

export default Farmers;
