import React from 'react';
import { Link } from 'react-router-dom';
import WithoutSearchBar from '../../../header/WithoutSearchBar';

const LoanDetailsAndPurpose = () => {
  return (
    <div className="container">
      <div className="header-comonent border-bottom">
        <WithoutSearchBar title="Loan Details And Details" />
      </div>

      <div className="border-bottom p-3 my-4">
        <div className="row mb-2 mt-3">
          <div className="col-md-3">
            <label className="mb-2">Loan Product</label>
            <input type="text" className="form-control" placeholder="Cash Loan" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Loan Category</label>
            <input type="text" className="form-control" placeholder="123510003" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Interest Rate</label>
            <input type="text" className="form-control" placeholder="18%" />
          </div>
        </div>
      </div>

      <h3 className="mb-3">Loan Calculations</h3>
      <div className="p-3 mb-4">
        <div className="row mb-3 mt-2">
          <div className="col-md-3">
            <label className="mb-2">Apply Amount</label>
            <input type="text" className="form-control" placeholder="$ 5000" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Down Payment</label>
            <input type="text" className="form-control" placeholder="0" />
          </div>
        </div>

        <div className="row mb-3 mt-4">
          <div className="col-md-3">
            <label className="mb-2">Cover Ratio</label>
            <input type="email" className="form-control" placeholder="156.0" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Tenure</label>
            <input type="text" className="form-control" placeholder="24 Months" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Frequency</label>
            <input type="text" className="form-control" placeholder="2 Months" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Cycle</label>
            <input type="text" className="form-control" placeholder="1" />
          </div>
        </div>

        <div className="row mb-3 mt-4">
          <div className="col-md-3">
            <label className="mb-2">Repayment Amount</label>
            <input type="text" className="form-control" placeholder="$ 6333" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Business Currency</label>
            <input type="email" className="form-control" placeholder="USD" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Repay Currency</label>
            <input type="text" className="form-control" placeholder="USD" />
          </div>
          <div className="col-md-3">
            <label className="mb-2">Expense Currency</label>
            <input type="text" className="form-control" placeholder="USD" />
          </div>
        </div>
      </div>


      <Link to="/loanApplicationForm">
        <button className="btn btn-light border-dark m-3">Back</button>
      </Link>
      <Link to="checkListItems">
        <button className="btn btn-dark">Next</button>
      </Link>
    </div>
  );
};

export default LoanDetailsAndPurpose;
