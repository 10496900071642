import React, { useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';

const MapInfoGraph = () => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    // Hardcoded static data for the chart
    const staticData = {
      series: [
        {
          name: 'Example Data',
          data: [30, 40, 45, 50, 49, 60, 70],  // Y-axis data (values)
        },
      ],
      options: {
        chart: { id: 'line-chart', type: 'line', height: 350 },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],  // X-axis categories (months)
          title: { text: 'Month' },
        },
        yaxis: {
          title: { text: 'Value' },
          labels: {
            formatter: (val) => `${val}`,  // Format labels on Y-axis if needed
          },
        },
        stroke: { curve: 'smooth', width: 2 },
        fill: { opacity: 0.3 },
        markers: { size: 5 },
        tooltip: {
          shared: true,
          intersect: false,
          x: { show: true },
          y: { formatter: (val) => `Value: ${val}` },
        },
        grid: { show: true },
      },
    };

    setChartData(staticData);  // Set the hardcoded data
  }, []);  // Empty dependency array ensures this runs only once on mount

  if (!chartData) {
    return <div>No data available</div>;
  }

  return (
    <div className='shadow rounded'>
      <ApexCharts options={chartData.options} series={chartData.series} type="line" height={350} />
    </div>
  );
};

export default MapInfoGraph;
