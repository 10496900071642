
const DashboardIcon = () =>{
    return(
        <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0349 2.03462C13.6676 1.40188 14.5609 1.14575 15.5835 1.14575H17.4168C18.4395 1.14575 19.3327 1.40188 19.9655 2.03462C20.5982 2.66735 20.8543 3.56061 20.8543 4.58325V6.41659C20.8543 7.43923 20.5982 8.33248 19.9655 8.96522C19.3327 9.59796 18.4395 9.85408 17.4168 9.85408H15.5835C14.5609 9.85408 13.6676 9.59796 13.0349 8.96522C12.4021 8.33248 12.146 7.43923 12.146 6.41659V4.58325C12.146 3.56061 12.4021 2.66735 13.0349 2.03462ZM14.0071 3.00689C13.7232 3.29082 13.521 3.77256 13.521 4.58325V6.41659C13.521 7.22728 13.7232 7.70902 14.0071 7.99295C14.2911 8.27688 14.7728 8.47908 15.5835 8.47908H17.4168C18.2275 8.47908 18.7093 8.27688 18.9932 7.99295C19.2771 7.70902 19.4793 7.22728 19.4793 6.41659V4.58325C19.4793 3.77256 19.2771 3.29082 18.9932 3.00689C18.7093 2.72296 18.2275 2.52075 17.4168 2.52075H15.5835C14.7728 2.52075 14.2911 2.72296 14.0071 3.00689Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.03486 13.0346C2.6676 12.4019 3.56085 12.1458 4.5835 12.1458H6.41683C7.43947 12.1458 8.33273 12.4019 8.96547 13.0346C9.5982 13.6674 9.85433 14.5606 9.85433 15.5833V17.4166C9.85433 18.4392 9.5982 19.3325 8.96547 19.9652C8.33273 20.598 7.43947 20.8541 6.41683 20.8541H4.5835C3.56085 20.8541 2.6676 20.598 2.03486 19.9652C1.40212 19.3325 1.146 18.4392 1.146 17.4166V15.5833C1.146 14.5606 1.40212 13.6674 2.03486 13.0346ZM3.00713 14.0069C2.7232 14.2908 2.521 14.7726 2.521 15.5833V17.4166C2.521 18.2273 2.7232 18.709 3.00713 18.9929C3.29106 19.2769 3.7728 19.4791 4.5835 19.4791H6.41683C7.22752 19.4791 7.70926 19.2769 7.99319 18.9929C8.27712 18.709 8.47933 18.2273 8.47933 17.4166V15.5833C8.47933 14.7726 8.27712 14.2908 7.99319 14.0069C7.70926 13.723 7.22752 13.5208 6.41683 13.5208H4.5835C3.7728 13.5208 3.29106 13.723 3.00713 14.0069Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.50016 2.52075C3.85481 2.52075 2.521 3.85457 2.521 5.49992C2.521 7.14527 3.85481 8.47908 5.50016 8.47908C7.14551 8.47908 8.47933 7.14527 8.47933 5.49992C8.47933 3.85457 7.14551 2.52075 5.50016 2.52075ZM1.146 5.49992C1.146 3.09518 3.09542 1.14575 5.50016 1.14575C7.9049 1.14575 9.85433 3.09518 9.85433 5.49992C9.85433 7.90466 7.9049 9.85408 5.50016 9.85408C3.09542 9.85408 1.146 7.90466 1.146 5.49992Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5002 13.5208C14.8548 13.5208 13.521 14.8546 13.521 16.4999C13.521 18.1453 14.8548 19.4791 16.5002 19.4791C18.1455 19.4791 19.4793 18.1453 19.4793 16.4999C19.4793 14.8546 18.1455 13.5208 16.5002 13.5208ZM12.146 16.4999C12.146 14.0952 14.0954 12.1458 16.5002 12.1458C18.9049 12.1458 20.8543 14.0952 20.8543 16.4999C20.8543 18.9047 18.9049 20.8541 16.5002 20.8541C14.0954 20.8541 12.146 18.9047 12.146 16.4999Z" />
        </svg>
    )
}

export default DashboardIcon;