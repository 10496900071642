import React from 'react';
import "./header.css";

function WithSearchBar({ title }) {
  return (
    <div className="d-flex align-items-center justify-content-between m-4">
      <div>
        <h2 className="mb-0">{ title }</h2>
      </div>

      <div className="d-flex align-items-center">
        <div className="form-group mb-0 me-3 w-50">
          <input type="text" className="form-control rounded-pill" placeholder="Search for anything..."/>
        </div>
   
        <div className="me-3">
          <img src={'/assets/icons/notification.png'} alt="notification" className="img-fluid"/>
        </div>
    
        <div className="header-profile dropdown border rounded-pill">
          <div className="d-flex align-items-center dropdown-toggle" id="profileDropdown" data-bs-toggle="dropdown" aria-expanded="false" role="button">

            <img src={'/assets/icons/profile.png'} alt="profile" className="rounded-circle me-2"/>
            <div>
              <h6 className="mb-0">Parwez</h6>
              <small className="text-muted">Admin</small>
            </div>
          </div>
          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="profileDropdown">
            <li><a className="dropdown-item" href="#">Profile</a></li>
            <li><a className="dropdown-item" href="#">Logout</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default WithSearchBar;
