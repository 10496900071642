import React from 'react';
import { Link } from 'react-router-dom';
import WithSearchBar from '../../../header/WithSearchBar';

const BussinessType = () => {
    return (
        <div className="container">
            <div className="header-comonent border-bottom">
                <WithSearchBar title="Bussiness Type" />
            </div>

            <div className="border-bottom p-3 my-4">
                <div className="row mb-3 mt-2">
                    <div className="col-md-3">
                        <label className="mb-2">Bussiness Type</label>
                        <input type="text" className="form-control" placeholder="Paddy Farming" />
                    </div>
                </div>

                <div className="row mb-3 mt-4">
                    <div className="col-md-3">
                        <label className="mb-2">Label1</label>
                        <input type="email" className="form-control" placeholder="HA" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Amount1</label>
                        <input type="text" className="form-control" placeholder="4.000000000" />
                    </div>
                </div>

                <div className="row mb-3 mt-4">
                    <div className="col-md-3">
                        <label className="mb-2">Label2</label>
                        <input type="email" className="form-control" placeholder="T/Ha" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Amount2</label>
                        <input type="text" className="form-control" placeholder="4.000000000" />
                    </div>
                </div>

                <div className="row mb-3 mt-4">
                    <div className="col-md-3">
                        <label className="mb-2">Label3</label>
                        <input type="email" className="form-control" placeholder="USD/T" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Amount3</label>
                        <input type="text" className="form-control" placeholder="275.00" />
                    </div>
                </div>

                <div className="row mb-3 mt-4">
                    <div className="col-md-3">
                        <label className="mb-2">Revenue Total</label>
                        <input type="text" className="form-control" placeholder="4,400.00" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Income Rate</label>
                        <input type="email" className="form-control" placeholder="40" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Income Total</label>
                        <input type="text" className="form-control" placeholder="1,760.00" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Harvest Month</label>
                        <input type="text" className="form-control" placeholder="Sept" />
                    </div>
                </div>
            </div>

            <div className="p-3 my-4">
                <div className="row mb-3 mt-2">
                    <div className="col-md-3">
                        <label className="mb-2">Bussiness Type</label>
                        <input type="text" className="form-control" placeholder="Cassava Farm" />
                    </div>
                </div>

                <div className="row mb-3 mt-4">
                    <div className="col-md-3">
                        <label className="mb-2">Label1</label>
                        <input type="email" className="form-control" placeholder="HA" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Amount1</label>
                        <input type="text" className="form-control" placeholder="2.000000000" />
                    </div>
                </div>

                <div className="row mb-3 mt-4">
                    <div className="col-md-3">
                        <label className="mb-2">Label2</label>
                        <input type="email" className="form-control" placeholder="T/HA" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Amount2</label>
                        <input type="text" className="form-control" placeholder="23.000000000" />
                    </div>
                </div>

                <div className="row mb-3 mt-4">
                    <div className="col-md-3">
                        <label className="mb-2">Label3</label>
                        <input type="email" className="form-control" placeholder="HA" />
                    </div>
                </div>

                <div className="row mb-3 mt-4">
                    <div className="col-md-3">
                        <label className="mb-2">Revenue Total</label>
                        <input type="text" className="form-control" placeholder="4,400.00" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Income Rate</label>
                        <input type="email" className="form-control" placeholder="40" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Income Total</label>
                        <input type="text" className="form-control" placeholder="1,760.00" />
                    </div>
                    <div className="col-md-3">
                        <label className="mb-2">Harvest Month</label>
                        <input type="text" className="form-control" placeholder="Sept" />
                    </div>
                </div>
            </div>

            <Link to="/loanApplicationForm/loanDetailsAndPurpose/checkListItems/guarantor">
                <button className="btn btn-light border-dark m-3">Back</button>
            </Link>
            <Link to="livingExpense">
                <button className="btn btn-dark">Next</button>
            </Link>
        </div>
    );
};

export default BussinessType;
