import React from 'react'

const Kycs = () => {
  return (
    <div>
      Kycs
    </div>
  )
}

export default Kycs